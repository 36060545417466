/**
 *
 * Combine all reducers in this file and export the combined reducers.
 *
 */

import { combineReducers, Reducer } from '@reduxjs/toolkit'
import api from 'api'
import awsApi from 'api/aws'

import apiError from './api_error/slice'
import authSlice, { clearCredentials } from './auth/slice'

/**
 * Merges the main reducer with the router state
 */
const appReducer = combineReducers({
  apiError,
  [api.reducerPath]: api.reducer,
  [awsApi.reducerPath]: awsApi.reducer,
  [authSlice.name]: authSlice.reducer,
})

type RootState = ReturnType<typeof appReducer>

const rootReducer: Reducer<RootState> = (state, action) => {
  if (clearCredentials.match(action)) {
    state = {} as RootState
  }

  return appReducer(state, action)
}

export { rootReducer }
