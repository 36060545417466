import { ToastContainer } from 'react-toastify'
import tw, { styled } from 'twin.macro'

export const Wrapper = styled(ToastContainer)`
  width: inherit;
  .Toastify__toast-theme--colored.Toastify__toast--error {
    ${tw`bg-feedback-danger-dark text-white font-montserrat-medium text-center`};
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
    ${tw`bg-feedback-success-darkest text-white font-montserrat-medium text-center`};
  }
  .Toastify__toast-theme--colored.Toastify__toast--warning {
    ${tw`bg-feedback-warning-dark text-white font-montserrat-medium text-center`};
  }
`
