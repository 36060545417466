import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { SendPhotoToAwsRequest } from './types'

const awsApi = createApi({
  reducerPath: 'api/aws',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['Files'],
  endpoints: builder => ({
    sendPhotoToAws: builder.mutation<void, SendPhotoToAwsRequest>({
      query: ({ url, file }) => ({
        url,
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      }),
    }),
  }),
})

export const { useSendPhotoToAwsMutation } = awsApi

export default awsApi
