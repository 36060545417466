import { Action, AnyAction } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { parseCookies } from 'nookies'

export const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL

export const TOKEN_KEY = '@cride/token'
export const REFRESH_TOKEN_KEY = '@cride/refresh-token'

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: headers => {
    const token = parseCookies()[TOKEN_KEY]
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth: typeof baseQuery = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error?.status === 401) {
    const refreshToken = parseCookies()[REFRESH_TOKEN_KEY]
    const refreshResult = await baseQuery(
      {
        url: '/auth/refresh-token',
        method: 'POST',
        body: { refreshToken },
      },
      api,
      extraOptions,
    )
    if (refreshResult.data) {
      const action: AnyAction = {
        type: 'auth/setCredentials',
        payload: refreshResult.data,
      }
      api.dispatch(action)
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions)
    } else {
      const action: Action = { type: 'auth/clearCredentials' }
      api.dispatch(action)
    }
  }
  return result
}

const api = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Account', 'Bank', 'Vehicle'],
  endpoints: () => ({}),
})

export default api
