import '@stripe/stripe-js'

import { cache } from '@emotion/css'
import { CacheProvider } from '@emotion/react'
import ToastContainer from 'components/Common/Toast/ToastContainer'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Provider } from 'react-redux'
import store from 'store'
import GlobalStyles from 'styles/globals'

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => (
  <>
    <Head>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />
    </Head>
    <Provider store={store}>
      <CacheProvider value={cache}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        >
          <GlobalStyles />
          <ToastContainer />
          <Component {...pageProps} />
        </GoogleReCaptchaProvider>
      </CacheProvider>
    </Provider>
  </>
)

export default appWithTranslation(MyApp)
