import Toast from 'components/Common/Toast/ToastBody'
import { toast, ToastOptions } from 'react-toastify'

type ErrorOptions = {
  toastOptions?: ToastOptions<any>
  type?: 'default' | 'form'
}

const defaultErrorToastOptions: ToastOptions<any> = {
  hideProgressBar: true,
  autoClose: 8000,
  position: 'top-center',
  theme: 'colored',
  type: 'error',
  icon: false,
}
const formErrorToastOptions: ToastOptions<any> = {
  autoClose: 8000,
  position: 'top-center',
}

export function showErrorToast(
  errorMessages: string[],
  options?: ErrorOptions,
) {
  let toastOptions: ToastOptions<any> = {
    ...defaultErrorToastOptions,
    ...options?.toastOptions,
  }

  if (options?.type === 'form') {
    toastOptions = {
      ...toastOptions,
      ...formErrorToastOptions,
      ...options.toastOptions,
    }
  }

  return toast(<Toast type="error" messages={errorMessages} />, toastOptions)
}

export function showSuccessToast(messages: string[]) {
  return toast.success(<Toast messages={messages} type="success" />, {
    hideProgressBar: true,
    autoClose: 5000,
    position: 'top-center',
    theme: 'colored',
    type: 'success',
    icon: false,
  })
}

export function showWarningToast(messages: string[]) {
  return toast.warning(<Toast messages={messages} type="warning" />, {
    hideProgressBar: true,
    autoClose: 8000,
    position: 'top-center',
    theme: 'colored',
    type: 'warning',
    icon: false,
  })
}
