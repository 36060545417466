// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_RELEASE =
  process.env.SENTRY_RELEASE ||
  `${process.env.NEXT_PUBLIC_APP_VERSION}.${process.env.NEXT_PUBLIC_GIT_COMMIT_COUNT}`
const SENTRY_ENVIRONMENT =
  process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://b71616886de34108ae8c654424a6c0e9@o287944.ingest.sentry.io/6516065',
  tracesSampleRate: 0.2,
  release: SENTRY_RELEASE,
  environment: SENTRY_ENVIRONMENT,
})
