import api from 'api'
import { Credential } from 'api/types'

import {
  ConfirmEmailRequest,
  ConfirmEmailResponse,
  ForgotPasswordRequest,
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequest,
} from './types'

const endpoints = {
  login: 'auth/driver',
  register: 'accounts/driver',
  confirmEmail: 'accounts/driver/confirm',
  forgotPassword: 'auth/driver-portal/forgot-password',
  resetPassword: 'auth/driver-portal/reset-password',
}

const authApi = api.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<Credential, LoginRequest>({
      query: body => ({
        url: endpoints.login,
        method: 'POST',
        body,
      }),
    }),
    register: builder.mutation<void, RegisterRequest>({
      query: body => ({
        url: endpoints.register,
        method: 'POST',
        body,
      }),
    }),
    forgotPassword: builder.mutation<Credential, ForgotPasswordRequest>({
      query: body => ({
        url: endpoints.forgotPassword,
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<Credential, ResetPasswordRequest>({
      query: body => ({
        url: endpoints.resetPassword,
        method: 'POST',
        body,
      }),
    }),
    confirmEmail: builder.mutation<ConfirmEmailResponse, ConfirmEmailRequest>({
      query: body => ({
        url: endpoints.confirmEmail,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: true,
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useConfirmEmailMutation,
} = authApi

export default authApi
