import { Middleware } from 'redux'

import { showApiError } from '../api_error/slice'
import { AppState } from '../types'

const apiErrorMiddleware: Middleware<any, AppState> = () => {
  return next => action => {
    if (/api.*rejected/i.test(action.type) && action.payload?.status !== 403) {
      if (action.payload?.data?.message) {
        next(showApiError({ messages: [action.payload?.data?.message] }))
      } else if (action.payload?.data?.error) {
        next(showApiError({ messages: [action.payload?.data?.error] }))
      } else if (action.payload?.data?.app) {
        next(showApiError({ messages: action.payload?.data?.app }))
      }
      return next(action)
    }
    return next(action)
  }
}

export default apiErrorMiddleware
