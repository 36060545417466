import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from 'api'
import authApi from 'api/auth'
import { Credential } from 'api/types'
import { setCookie } from 'nookies'

import { AuthState } from './types'

const initialState: AuthState = {
  account: undefined,
  resetPasswordKey: '',
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      _state,
      {
        payload: { authToken, refreshToken, expiresIn },
      }: PayloadAction<Credential>,
    ) => {
      const maxAge = (expiresIn ?? 0) * 2
      const path = '/'
      setCookie(null, TOKEN_KEY, authToken, {
        maxAge,
        path,
      })
      setCookie(null, REFRESH_TOKEN_KEY, refreshToken || '', {
        maxAge,
        path,
      })
    },
    clearCredentials: () => {
      const maxAge = 0
      const path = '/'
      setCookie(null, TOKEN_KEY, '', {
        maxAge,
        path,
      })
      setCookie(null, REFRESH_TOKEN_KEY, '', {
        maxAge,
        path,
      })
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      authApi.endpoints.confirmEmail.matchFulfilled,
      (state, action) => {
        state.account = action.payload.account
        state.resetPasswordKey = action.payload.resetPassword
      },
    )
  },
})

export const { setCredentials, clearCredentials } = slice.actions

export default slice
