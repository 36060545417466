/**
 *
 * Toast
 *
 */

import { memo } from 'react'

import * as S from './styles'

export interface ToastProps {
  messages: string[]
  type: 'error' | 'warning' | 'success'
}

const Toast = ({ messages }: ToastProps) => {
  return (
    <S.Wrapper>
      {messages.map(msg => (
        <span key={msg}>{msg}</span>
      ))}
    </S.Wrapper>
  )
}

export default memo(Toast)
