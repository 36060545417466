import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { showErrorToast } from 'utils/toast'

const slice = createSlice({
  name: 'apiError',
  initialState: {},
  reducers: {
    showApiError: (_, { payload }: PayloadAction<{ messages: string[] }>) => {
      showErrorToast(payload.messages)
    },
  },
})

export const { showApiError } = slice.actions
export default slice.reducer
