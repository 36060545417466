/**
 *
 * Create the store with dynamic reducers
 *
 */

import { configureStore } from '@reduxjs/toolkit'

import api from '../api'
import awsApi from '../api/aws'
import apiErrorMiddleware from './middleware/apiErrorMiddleware'
import { rootReducer } from './reducers'

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(awsApi.middleware)
      .concat(apiErrorMiddleware),
})

export default store
