import 'react-toastify/dist/ReactToastify.css'

import { Global } from '@emotion/react'
import React from 'react'
import tw, { css, GlobalStyles as BaseStyles } from 'twin.macro'

const customStyles = css([
  css`
    @font-face {
      font-family: 'Montserrat-Regular';
      font-style: normal;
      font-weight: normal;
      src: url('/fonts/montserrat-regular.woff2') format('woff2');
      font-display: swap;
    }
    @font-face {
      font-family: 'Montserrat-Medium';
      font-style: normal;
      font-weight: 500;
      src: url('/fonts/montserrat-medium.woff2') format('woff2');
      font-display: swap;
    }
    @font-face {
      font-family: 'Montserrat-Semibold';
      font-style: normal;
      font-weight: 600;
      src: url('/fonts/montserrat-semibold.woff2') format('woff2');
      font-display: swap;
    }
    @font-face {
      font-family: 'Montserrat-Bold';
      font-style: normal;
      font-weight: bold;
      src: url('/fonts/montserrat-bold.woff2') format('woff2');
      font-display: swap;
    }
    .grecaptcha-badge {
      display: none;
    }
  `,
  {
    body: {
      WebkitTapHighlightColor: 'transparent',
      ...tw`antialiased`,
    },
  },
])

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
)

export default GlobalStyles
